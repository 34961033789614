import React from 'react';
import SearchIndex from '../../components/search/index';

const SearchIndexPage = () => {
    return(
        <>
            <SearchIndex />
        </>
    )
}

export default SearchIndexPage;