import React from 'react';
import PaymentAccInfo from '../../components/search/paymentAccInfo';

const PaymentAccInfoPage = () => {
    return(
        <>
            <PaymentAccInfo />
        </>
    )
}

export default PaymentAccInfoPage;