export const constants = {
    apartmentTypes: [
        "Bungalow",
        "Duplex",
        "Terrace",
        "Flat",
        "Apartment",
        "Maisonette",
        "House",
    ],
}