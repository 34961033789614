import React from 'react';
import SearchSingle from '../../components/search/singleProperty';

const SearchSinglePage = () => {
    return(
        <>
            <SearchSingle />
        </>
    )
}

export default SearchSinglePage;