import React from 'react';
import Calculator from '../../components/search/calculator';

const CalculatorPage = () => {
    return(
        <>
            <Calculator />
        </>
    )
}

export default CalculatorPage;