import React from 'react';
import Signup from '../../components/auth/Signup/index';

const SignupPage = () => {
    return(
        <>
            <Signup />
        </>
    )
}

export default SignupPage;