import React, { useState } from 'react';
import { BoxFlex, BreadcrumbArea, DescHeader, IconFlex, Line, MainWrap, PropertyWrap, RandomCircle } from '../../styles/reusable/index';
import Typography from '../reusable/typography';
import AuthHeaderComp from '../auth/authHeader';
import { AuthBacknav } from '../../styles/authentication';
import { InputWrap, InputField } from '../../styles/authentication';
import { Button } from '../../styles/reusable';
import * as Icon from 'react-feather';
import { CalcDetailsWrap, CalculatorFlex, LineFlex } from './style';
import Error from '../reusable/error';
import AgreementModal from './agreementModal';
import { useNavigate } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/24/solid';

const SearchIndex = () => {

    const [showAgreement, setShowAgreement] = useState(false);
    const navigate = useNavigate();
    
    return(
        <>
            <AuthHeaderComp />
            <MainWrap
                top='2rem'
                width='100%'
                maxWidth='1300px'
            >
                <AuthBacknav
                    onClick={() => navigate(-1)}
                >
                    <Icon.ArrowLeft 
                        color='#8796AD' 
                        size={20}
                    />
                    <p>Back</p>
                </AuthBacknav>
            </MainWrap>
            <MainWrap
                top='-0.5rem'
            >
                <CalculatorFlex>
                    <div>
                        <Typography 
                            text='Contribution Calculator'
                            color='#091525'
                            fontWeight={600}
                            fontSize='32px'
                            lineHeight='32px'
                        />
                        <Typography 
                            text='Check if you’re eligible to contribute for a property'
                            color='#245372'
                            fontWeight={400}
                            fontSize='15px'
                            lineHeight='22.5px'
                            margin='0.5rem 0 0 0'
                        />
                        <InputWrap>
                            <InputField
                                margin='2rem 0 1rem 0'
                            >
                                <p>Property Value</p>
                                <input
                                    autoComplete="off"
                                    placeholder='₦100,000,000'
                                    disabled
                                />
                                <i>
                                    <LockClosedIcon className='w-6 h-5' color='#8796AD' />
                                </i>
                            </InputField>
                            <InputField
                                margin='0 0 1rem 0'
                            >
                                <p>Monthly Salary</p>
                                <input
                                    autoComplete="off"
                                    placeholder='Enter monthly salary'
                                />
                            </InputField>
                            <InputField
                                margin='0 0 1rem 0'
                            >
                                <p>Pension (RSA) Balance</p>
                                <input
                                    autoComplete="off"
                                    placeholder='Enter RSA Balance'
                                />
                            </InputField>
                            <InputField
                                margin='0 0 1rem 0'
                            >
                                <p>Age</p>
                                <input
                                    autoComplete="off"
                                    placeholder='Enter your age'
                                />
                            </InputField>
                            <InputField
                                margin='0rem 0 1rem 0'
                                width='48%'
                            >
                                <p>Interest</p>
                                <select 
                                    autoComplete="off"
                                >
                                    <option>Select Interest</option>
                                </select>
                            </InputField>
                            <InputField
                                margin='0rem 0 1rem 0'
                                width='48%'
                            >
                                <p>Duration</p>
                                <select 
                                    autoComplete="off"
                                >
                                    <option>Select Duration</option>
                                </select>
                            </InputField>
                            <Button
                                bg='var(--primary-color)'
                                color='#fff'
                                type='submit'
                                width='100%'
                                top='5px'
                            >
                                Calculate
                            </Button>
                        </InputWrap>
                    </div>
                    <div>
                        <Typography 
                            text='₦220,833.33'
                            color='#091525'
                            fontWeight={600}
                            fontSize='32px'
                            lineHeight='32px'
                            align='center'
                        />
                        <Typography 
                            text='Minimum monthly contribution'
                            color='#245372'
                            fontWeight={400}
                            fontSize='15px'
                            lineHeight='22.5px'
                            margin='0.5rem 0 0 0'
                            align='center'
                        />
                        <CalcDetailsWrap>
                            <LineFlex>
                                <p>Total Equity Contribution</p>
                                <h3>₦1,500,000</h3>
                            </LineFlex>
                            <Line />
                            <LineFlex>
                                <p>Duration of Contribution</p>
                                <h3>6 months</h3>
                            </LineFlex>
                        </CalcDetailsWrap>
                        <CalcDetailsWrap>
                            <LineFlex>
                                <p>Property Value</p>
                                <h3>₦10,000,000</h3>
                            </LineFlex>
                            <Line />
                            <LineFlex>
                                <p>Downpayment</p>
                                <h3>₦2,000,000</h3>
                            </LineFlex>
                            <Line />
                            <LineFlex>
                                <p>25% RSA Value</p>
                                <h3>₦675,000</h3>
                            </LineFlex>
                        </CalcDetailsWrap>
                        <Error
                            message='Equity contribution is 20% of the Property Value minus 25% of RSA Value.'
                            color='#1B2229'
                            bg='#F0F3F6'
                            border='none'
                        />
                        <Button
                            bg='var(--primary-color)'
                            color='#fff'
                            type='submit'
                            width='100%'
                            top='40px'
                            onClick={() => setShowAgreement(true)}
                        >
                            Start Contribution
                        </Button>
                    </div>
                </CalculatorFlex>
            </MainWrap>
            {
                showAgreement ?
                    <AgreementModal 
                        closeFunc={() => setShowAgreement(false)}
                        action={() => navigate('/send-payment')}
                    />
                    : null
            }
        </>
    )
}

export default SearchIndex;