import React, { useState } from 'react';
import { BoxFlex, Line, MainWrap, PageListItem, PageListItemWrap, PageToggleText,} from '../../styles/reusable/index';
import SideBarWidget from '../reusable/sidebar';
import { DashboardFlex, DashboardHeader, DashboardInner, DashboardMain, RecentSection, SavedSearchSection, SavedWrap } from './style';
import QuickActionWidget from '../reusable/quickaction';
import Typography from '../reusable/typography';
import { Link, useNavigate } from 'react-router-dom';
import PropertyCard from '../reusable/propertyCard';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import BottomNavComp from '../reusable/bottomNav';

const DashboardSavedProperties = () => {
    
    const navigate = useNavigate();
    const [activePage, setActivePage] = useState('Overview');

    return(
        <>
            <MainWrap
                top='0rem'
                width='100%'
                maxWidth='1200px'
            >
                <DashboardFlex>
                    <SideBarWidget />
                    <DashboardMain>
                        <DashboardHeader
                            padding='20px 24px'
                        >
                            <Typography 
                                text='Saved'
                                color='#091525'
                                fontWeight={500}
                                fontSize='16px'
                                lineHeight='17.6px'
                            />
                        </DashboardHeader>
                        <SavedSearchSection>
                            <div>
                                <i><MagnifyingGlassIcon className='w-4 h-4' /></i>
                                <input 
                                    placeholder='Search'
                                />
                            </div>
                        </SavedSearchSection>
                        <DashboardInner
                            padding='5px 70px'
                        >
                            <SavedWrap>
                            {
                                properties.map((item, index) => (
                                    <Link key={index} to={`/property/itemName`}>
                                        <PropertyCard 
                                            key={index}
                                            img={item.img}
                                            saved={item.saved}
                                        />
                                    </Link>
                                ))
                            }
                            </SavedWrap>
                        </DashboardInner>
                        <Line />
                    </DashboardMain>
                    <QuickActionWidget />
                </DashboardFlex>
                <BottomNavComp />
            </MainWrap>
        </>
    )
}

export default DashboardSavedProperties;

const pageItems = ['Overview', 'Project Progress', 'Payment History']

export const properties = [
    {
        img: '/images/house1.png',
        saved: true
    },
    {
        img: '/images/house2.png',
        saved: true
    },
    {
        img: '/images/house1.png',
        saved: true
    },
    {
        img: '/images/house2.png',
        saved: true
    },
    {
        img: '/images/house1.png',
        saved: true
    },
    {
        img: '/images/house2.png',
        saved: true
    },
    {
        img: '/images/house1.png',
        saved: true
    },
    {
        img: '/images/house2.png',
        saved: true
    },
]