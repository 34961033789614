import PropertyCardSkeleton from "./propertyCard";

export default function PropertiesSkeleton() {
  return (
    <div className="py-4 w-full mx-auto">
      <div className="grid grid-cols-3 gap-[48px]">
        {Array(9)
          .fill(0)
          .map((item, index) => (
            <PropertyCardSkeleton key={index} />
          ))}
      </div>
    </div>
  );
}
